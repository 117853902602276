import { Routes } from '@angular/router';

import { ApiPage, GotoPage, ConfirmPage } from '@btlib-core';

// module'\).then\(m => m.(.+)Module\)
// page').then(m => m.$1)
export const routesCommon: Routes = [
  {
    path: 'order',
    children: [
      {
        path: 'form',
        children: [
          {   path: 'kjerringtorget',  loadComponent: () => import('./pages/order/form/order-form-kjerringtorget/order-form-kjerringtorget.page').then(m => m.OrderFormKjerringtorgetPage)  },   
          {   path: 'route',  loadComponent: () => import('./pages/order/form/order-form-route/order-form-route.page').then(m => m.OrderFormRoutePage)  },  
          {   path: 'fast-checkout',  loadComponent: () => import('./pages/order/form/fast-checkout/fast-checkout.page').then(m => m.FastCheckoutPage)  },
          {   path: 'spacebooking',  loadComponent: () => import('./pages/order/form/spacebooking/spacebooking.page').then(m => m.SpacebookingPage)  },
        
        ]
      }
    ]
  },


  {
    path: 'products',
    children: [
      {   path: '',  loadComponent: () => import('./pages/products/products-root/products-root.page').then(m => m.ProductsRootPage)  }
    ]
  },

  {
    path: 'provider',
    children: [
      {
        path: 'route',
        children: [
          {   path: 'status',  loadComponent: () => import('./pages/provider/route/provider-route-status/provider-route-status.page').then(m => m.ProviderRouteStatusPage)  },
   
        ]
      }
    ]
  },

  {
    path: 'sitequeue',
    children: [
      {   path: 'queue',  loadComponent: () => import('./pages/sitequeue/queue/queue.page').then(m => m.QueuePage)  }
    ]
  },


  {
    path: 'test',
    children: [
      {   path: 'iframe',  loadComponent: () => import('./pages/test/iframe/iframe.page').then(m => m.IframePage)  }
    ]
  },

  //
  
  {
    path: "",
    loadComponent: () => import("./pages/index/index.page").then((m) => m.IndexPage),
    
  },
  {
    path: "template-page",
    loadComponent: () => import("./pages/template-page/template-page.page").then((m) => m.TemplatePagePage),
    
  },
  {
    path: "lib",
    children: [
      { path: "api", component: ApiPage },
      { path: "goto", component: GotoPage },
      { path: "user", children: [
        { path: "confirm", component: ConfirmPage },
      ]},
    ]
  },


  



];


export const routes: Routes = [

  ...routesCommon,
    
  {
    path: 'api/:cid',
    children: routesCommon
  },

  { path: '**', redirectTo: '/', pathMatch: 'full' }
];
