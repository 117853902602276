import { ApplicationConfig, provideZoneChangeDetection, importProvidersFrom, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import nb from '@angular/common/locales/nb';

import { FormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient } from '@angular/common/http';


// import { de_DE } from 'ng-zorro-antd/i18n';
// const nb_NO:(typeof de_DE) = {
//   locale: 'nb',
//   Pagination: {
//     items_per_page: '/ side',
//     jump_to: 'Gå til side',
//     jump_to_confirm: 'bekreft',
//     page: 'Side',
//     prev_page: 'Forrige side',
//     next_page: 'Neste side',
//     prev_5: '5 forrige',
//     next_5: '5 neste',
//     prev_3: '3 forrige',
//     next_3: '3 neste',
//     page_size: 'sidestørrelse'
//   },
//   DatePicker: {
//     lang: {
//       placeholder: 'Velg dato',
//       yearPlaceholder: 'Velg år',
//       quarterPlaceholder: 'Velg kvartal',
//       monthPlaceholder: 'Velg måned',
//       weekPlaceholder: 'Velg uke',
//       rangePlaceholder: ['Startdato', 'Sluttdato'],
//       rangeYearPlaceholder: ['Startår', 'Sluttår'],
//       rangeQuarterPlaceholder: ['Startkvartal', 'Sluttkvartal'],
//       rangeMonthPlaceholder: ['Startmåned', 'Sluttmåned'],
//       rangeWeekPlaceholder: ['Start uke', 'Sluttuke'],
//       locale: 'nb_NO',
//       today: 'I dag',
//       now: 'Nå',
//       backToToday: 'Gå til i dag',
//       ok: 'Ok',
//       clear: 'Annuller',
//       month: 'Måned',
//       year: 'År',
//       timeSelect: 'Velg tidspunkt',
//       dateSelect: 'Velg dato',
//       weekSelect: 'Velg uke',
//       monthSelect: 'Velg måned',
//       yearSelect: 'Velg år',
//       decadeSelect: 'Velg tiår',
//       yearFormat: 'YYYY',
//       dateFormat: 'DD.MM.YYYY',
//       dayFormat: 'DD',
//       dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
//       monthBeforeYear: true,
//       previousMonth: 'Forrige måned (PageUp)',
//       nextMonth: 'Neste måned (PageDown)',
//       previousYear: 'Forrige år (Control + venstre)',
//       nextYear: 'Neste år (Control + høyre)',
//       previousDecade: 'Forrige tiår',
//       nextDecade: 'Neste tiår',
//       previousCentury: 'Forrige århundre',
//       nextCentury: 'Neste århundre'
//     },
//     timePickerLocale: {
//       placeholder: 'Velg tid',
//       rangePlaceholder: ['Starttid', 'Sluttid']
//     }
//   },
//   TimePicker: {
//     placeholder: 'Velg tid',
//     rangePlaceholder: ['Starttid', 'Sluttid']
//   },
//   Calendar: {
//     lang: {
//       placeholder: 'Velg dato',
//       yearPlaceholder: 'Velg år',
//       quarterPlaceholder: 'Velg kvartal',
//       monthPlaceholder: 'Velg måned',
//       weekPlaceholder: 'Velg uke',
//       rangePlaceholder: ['Startdato', 'Sluttdato'],
//       rangeYearPlaceholder: ['Startår', 'Sluttår'],
//       rangeMonthPlaceholder: ['Startmåned', 'Sluttmåned'],
//       rangeWeekPlaceholder: ['Start uke', 'Sluttuke'],
//       locale: 'nb_NO',
//       today: 'I dag',
//       now: 'Nå',
//       backToToday: 'Gå til i dag',
//       ok: 'Ok',
//       clear: 'Annuller',
//       month: 'Måned',
//       year: 'År',
//       timeSelect: 'Velg tidspunkt',
//       dateSelect: 'Velg dato',
//       weekSelect: 'Velg uke',
//       monthSelect: 'Velg måned',
//       yearSelect: 'Velg år',
//       decadeSelect: 'Velg tiår',
//       yearFormat: 'YYYY',
//       dateFormat: 'DD.MM.YYYY',
//       dayFormat: 'DD',
//       dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
//       monthBeforeYear: true,
//       previousMonth: 'Forrige måned (PageUp)',
//       nextMonth: 'Neste måned (PageDown)',
//       previousYear: 'Forrige år (Control + venstre)',
//       nextYear: 'Neste år (Control + høyre)',
//       previousDecade: 'Forrige tiår',
//       nextDecade: 'Neste tiår',
//       previousCentury: 'Forrige århundre',
//       nextCentury: 'Neste århundre'
//     },
//     timePickerLocale: {
//       placeholder: 'Velg tid',
//       rangePlaceholder: ['Starttid', 'Sluttid']
//     }
//   },
//   global: {
//     placeholder: 'Vennligst velg'
//   },
//   Table: {
//     filterTitle: 'Filtermeny',
//     filterConfirm: 'OK',
//     filterReset: 'Nullstill',
//     filterEmptyText: 'Ingen filtre',
//     emptyText: 'Ingen data',
//     selectAll: 'Velg alle',
//     selectInvert: 'Inverter gjeldende side',
//     selectionAll: 'Velg all data',
//     sortTitle: 'Sorter',
//     expand: 'Utvid rad',
//     collapse: 'Skjul rad',
//     triggerDesc: 'Sorter data i synkende rekkefølge',
//     triggerAsc: 'Sorterer data i stigende rekkefølge',
//     cancelSort: 'Klikk for å avbryte sorteringen',
//     filterCheckall: 'Velg alle elementer',
//     filterSearchPlaceholder: 'Søk i filtre',
//     selectNone: 'Tøm alle data'
//   },
//   Modal: {
//     okText: 'OK',
//     cancelText: 'Avbryt',
//     justOkText: 'OK'
//   },
//   Popconfirm: {
//     okText: 'OK',
//     cancelText: 'Avbryt'
//   },
//   Transfer: {
//     titles: ['', ''],
//     searchPlaceholder: 'Søk her',
//     itemUnit: 'element',
//     itemsUnit: 'elementer',
//     remove: 'Fjern',
//     selectCurrent: 'Velg gjeldende side',
//     removeCurrent: 'Fjern gjeldende side',
//     selectAll: 'Velg all data',
//     removeAll: 'Fjern all data',
//     selectInvert: 'Inverter gjeldende side'
//   },
//   Upload: {
//     uploading: 'Laster opp...',
//     removeFile: 'Fjern fil',
//     uploadError: 'Feil ved opplastning',
//     previewFile: 'Forhåndsvisning',
//     downloadFile: 'Last ned fil'
//   },
//   Empty: {
//     description: 'Ingen data'
//   },
//   Icon: {
//     icon: 'ikon'
//   },
//   Text: {
//     edit: 'Rediger',
//     copy: 'Kopier',
//     copied: 'Kopiert',
//     expand: 'Utvid'
//   },
//   PageHeader: {
//     back: 'Tilbake'
//   },
//   Image: {
//     preview: 'Forhåndsvis'
//   }
// }



import { 
  enUS as date_en_US, 
  nb as date_nb_NO 
} from 'date-fns/locale';

import { en_US, provideNzI18n, NZ_DATE_LOCALE, NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';
import { NzModalService  } from 'ng-zorro-antd/modal';

import { nb_NO } from 'ng-zorro-antd/i18n';

registerLocaleData(nb);

import { BooktechAppService, BT_INIT_CONFIG } from '@btlib-core';

import { environment } from './../environments/environment';
import { APP_CONFIG_INIT } from './../environments/config.init';
import { APP_VERSION_CODE, APP_VERSION_NAME } from './../environments/version';

function provideBtlib(env: any): EnvironmentProviders {
  return makeEnvironmentProviders([

    {
      provide: NzModalService
    
    },
    {
      provide: BooktechAppService
    
    },
    { provide: BT_INIT_CONFIG, useFactory: () => { return { env: environment, appConfig: APP_CONFIG_INIT, 
      version: { code: APP_VERSION_CODE, name: APP_VERSION_NAME } } } },

    { provide: NZ_DATE_LOCALE, useValue: date_nb_NO }, 
    { provide: NZ_DATE_CONFIG, useValue: {
        firstDayOfWeek: 1, // week starts on Monday (Sunday is 0), TODO: må endre dette til 0 på engelsk 
      }, 
    },

  ]);
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(routes), 
    provideClientHydration(), 
    // provideNzI18n(nb_NO),  //TODO: 
    provideNzI18n(en_US), 
    importProvidersFrom(FormsModule), 
    provideAnimationsAsync(), 
    provideHttpClient(),
    provideBtlib({ env: environment, appConfig: APP_CONFIG_INIT, 
      version: { code: APP_VERSION_CODE, name: APP_VERSION_NAME } }),
  ]
};
